export const CountriesCustome = [
  {
    code: "my",
    name: "Malaysia",
    fleetId: {
      dev: "gojosg",
      test: "gojosg",
      final: "gojosg"
    }
  },
  {
    code: "vn",
    name: "Vietnam",
    fleetId: {
      dev: "gojovn",
      test: "gojovn",
      final: "gojovn"
    }
  },
  // {
  //   code: 'fr',
  //   name: 'France',
  //   fleetId: {
  //     dev: 'hoanglocal',
  //     test: 'gojovn',
  //     final: 'gojofrance'
  //   }
  // }, {
  //   code: 'no',
  //   name: 'Norway',
  //   fleetId: {
  //     dev: 'hoanglocal',
  //     test: 'gojovn',
  //     final: 'gojonorway'
  //   }
  // }, {
  //   code: 'us',
  //   name: 'United States',
  //   fleetId: {
  //     dev: 'gojowi',
  //     test: 'gojovn',
  //     final: 'gojowi'
  //   }
  // }, {
  //   code: 'gb',
  //   name: 'Scotland',
  //   fleetId: {
  //     dev: 'hoanglocal',
  //     test: 'gojovn',
  //     final: 'gojoscotland'
  //   }
  // }, {
  //   code: 'th',
  //   name: 'Thailand',
  //   fleetId: {
  //     dev: 'gojothailand',
  //     test: 'gojothailand',
  //     final: 'gojothailand'
  //   }
  // },
  {
    code: "ph",
    name: "Philippines",
    fleetId: {
      dev: "gojoph",
      test: "gojoph",
      final: "gojoph"
    }
  },
  {
    code: "jo",
    name: "Jordan",
    fleetId: {
      dev: "gojojordan",
      test: "gojojordan",
      final: "gojojordan"
    }
  }
  //  {
  //   code: 'in',
  //   name: 'India',
  //   fleetId: {
  //     dev: 'gojoindia',
  //     test: 'gojoindia',
  //     final: 'gojoindia'
  //   }
  // }, {
  //   code: 'sa',
  //   name: 'Saudi Arabia',
  //   fleetId: {
  //     dev: 'gojosa',
  //     test: 'gojosa',
  //     final: 'gojosa'
  //   }
  // }, {
  //   code: 'ge',
  //   name: 'Georgia',
  //   fleetId: {
  //     dev: 'gojogeorgia',
  //     test: 'gojogeorgia',
  //     final: 'gojogeorgia'
  //   }
  // }, {
  //   code: 'pk',
  //   name: 'Pakistan',
  //   fleetId: {
  //     dev: 'gojopakistan',
  //     test: 'gojopakistan',
  //     final: 'gojopakistan'
  //   }
  // },
  // {
  //   code: 'ee',
  //   name: 'Estonia',
  //   fleetId: {
  //     dev: 'gojolatvia',
  //     test: 'gojolatvia',
  //     final: 'gojolatvia'
  //   }
  // }
]

export const CountriesHasMultiFleet = ["us"]

export const PathNameLanguages = [
  `vn-en`,
  `vn-vi`,
  `my-en`,
  `my-ms`,
  `my-zh`,
  `fr-fr`,
  `fr-en`,
  `no-no`,
  `no-en`,
  `us-en`,
  `th-th`,
  `th-en`,
  `ph-en`,
  `jo-en`,
  `jo-ar`,
  `in-en`,
  `sa-en`,
  `sa-ar`,
  `ge-en`,
  `ge-ka`,
  `ge-ru`,
  `pk-en`,
  `ee-en`
]

export const LanguageBasedCountry = {
  my: ["ms", "en", "zh"],
  vn: ["vi", "en"],
  fr: ["fr", "en"],
  no: ["no", "en"],
  th: ["th", "en"],
  us: ["en"],
  gb: ["en"],
  ph: ["en"],
  jo: ["ar", "en"],
  in: ["en"],
  sa: ["ar", "en"],
  ge: ["en", "ka", "ru"],
  pk: ["en"],
  ee: ["en"]
}
export const LanguageName = {
  en: "English",
  ms: "Bahasa Melayu",
  zh: "中文",
  vi: "Tiếng Việt",
  fr: "Français",
  no: "Norsk",
  gb: "Scottish Gaelic",
  th: "Thai",
  ph: "English",
  ar: "Arabic",
  ka: "Georgian",
  ru: "Russian"
}

export const CountryName = {
  vn: "Vietnam",
  my: "Malaysia",
  fr: "France",
  no: "Norway",
  us: "United States",
  gb: "Scotland",
  th: "Thailand",
  ph: "Philippines",
  jo: "Jordan",
  in: "India",
  sa: "Saudi Arabia",
  ge: "Georgia",
  pk: "Pakistan",
  ee: "Estonia"
}

export const UrlMarketPlaces = {
  vn: "https://www.youtube.com/embed/jawE_tM3r1U",
  my: "https://www.youtube.com/embed/sAP62lvckUI",
  fr: "https://www.youtube.com/embed/WEDq4mpG61E",
  no: "https://www.youtube.com/embed/raVcR377mPc",
  us: "https://www.youtube.com/embed/POa9hF3kyVE",
  gb: "https://www.youtube.com/embed/iyn61MaG3mM",
  th: "https://www.youtube.com/embed/iyn61MaG3mM",
  ph: "https://www.youtube.com/embed/iyn61MaG3mM",
  jo: "https://www.youtube.com/embed/iyn61MaG3mM",
  in: "https://www.youtube.com/embed/iyn61MaG3mM",
  sa: "https://www.youtube.com/embed/iyn61MaG3mM",
  ge: "https://www.youtube.com/embed/iyn61MaG3mM",
  pk: "https://www.youtube.com/embed/iyn61MaG3mM",
  ee: "https://www.youtube.com/embed/iyn61MaG3mM"
}

export const SupportEmails = {
  vn: "support.vn@gojo.global",
  my: "support.my@gojo.global",
  fr: "support.fr@gojo.global",
  no: "support.no@gojo.global",
  us: "support.tx@gojo.global",
  gb: "support.scot@gojo.global",
  th: "support.th@gojo.global",
  ph: "support@gojo.ph",
  jo: "support.jo@gojo.global",
  in: "maasupport@gojo.asia",
  sa: "support.sa@gojo.global",
  ge: "support.ge@gojo.global",
  pk: "support.pk@gojo.global",
  ee: "support.lv@gojo.global"
}

export const SupportPhone = {
  ge: "032 218 54 54"
}

export const Currencies = {
  vn: "VND",
  my: "RM",
  fr: "€",
  no: "kr",
  us: "$",
  gb: "€",
  th: "฿",
  ph: "₱",
  jo: "JOD",
  in: "₹",
  sa: "SAR",
  ge: "GEL",
  pk: "PKR",
  ee: "EEK"
}

export const SocialLinks = {
  vn: {
    fb: "https://www.facebook.com/gojovietnamofficial",
    ins: "https://www.instagram.com/gojo_vn",
    ytb: "https://www.youtube.com/channel/UClX3UrvU3mfPPlgkBFOhw1g"
  },
  my: {
    fb: "https://www.facebook.com/gojomalaysia/",
    ins: "https://www.instagram.com/gojomalaysia/",
    ytb: "https://www.youtube.com/gojomalaysia/"
  },
  fr: {
    fb: "https://www.facebook.com/GOJO-France-103371208471506",
    ins: "https://www.instagram.com/gojo_france/",
    ytb: "https://www.youtube.com/channel/UCZfV1nrsOqB5LDsK1xyXNUA"
  },
  no: {
    fb: "https://www.facebook.com/GOJO-Norway-112423204222330",
    ins: "https://www.instagram.com/gojonorway/",
    ytb: "https://www.youtube.com/channel/UCRuRGlvWR-AFWSIiDOxVRRA"
  },
  us: {
    fb: "https://www.facebook.com/GOJO-Texas-111853324338222",
    ins: "https://www.instagram.com/gojotexas/",
    ytb: "https://www.youtube.com/channel/UCEhKuyz1euHewlYZO30uzsw"
  },
  gb: {
    fb: "https://www.facebook.com/gojoscotland",
    ins: "https://www.instagram.com/gojoscotland/",
    ytb: "https://www.youtube.com/channel/UCKdFKYhRJnfhlSxsdRAfvbw"
  },
  th: {
    fb: "https://www.facebook.com/GOJO-Thailand-107287105092967",
    ins: "https://www.instagram.com/gojothailand",
    ytb: "https://www.youtube.com/channel/UCKdFKYhRJnfhlSxsdRAfvbw"
  },
  ph: {
    fb: "https://www.facebook.com/gojo.ph",
    ins: "https://www.instagram.com/gojothailand",
    ytb: "https://www.youtube.com/c/gojoglobal"
  },
  jo: {
    fb: "https://www.facebook.com/GojoNow",
    ins: "https://www.instagram.com/gojo.jordan1",
    ytb: "https://www.youtube.com/@gojo.jordan"
  },
  in: {
    fb: "https://www.facebook.com/GOJO-India-102175719137401",
    ins: "https://www.instagram.com/gojocabsindia/",
    ytb: "https://www.youtube.com/c/gojoglobal"
  },
  sa: {
    fb: "https://web.facebook.com/GOJO.JORDAN",
    ins: "https://www.instagram.com/gojo.jordan/",
    ytb: "https://www.youtube.com/c/gojoglobal"
  },
  ge: {
    fb: "https://www.facebook.com/gojomalaysia/",
    ins: "https://www.instagram.com/gojomalaysia/",
    ytb: "https://www.youtube.com/gojomalaysia/"
  },
  pk: {
    fb: "https://www.facebook.com/GoJoPakistan",
    ins: "https://www.instagram.com/gojomalaysia",
    ytb: "https://www.youtube.com/c/gojoglobal"
  },
  ee: {
    fb: "https://www.facebook.com/gojomalaysia/",
    ins: "https://www.instagram.com/gojomalaysia/",
    ytb: "https://www.youtube.com/gojomalaysia/"
  }
}

export const listHomeBannerUS = {
  wisconsin: {
    desktop: "../../../us/images/banner_wi.png",
    mobile: "../../../us/images/banner_mobile_wi.png"
  },
  illinois: {
    desktop: "../../../us/images/banner_il.png",
    mobile: "../../../us/images/banner_mobile_il.png"
  },
  texas: {
    desktop: "../../../us/images/banner_tx.png",
    mobile: "../../../us/images/banner_mobile_tx.png"
  }
}
